/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import { styled, Paper, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLicenses } from '../../../hooks';

export default function LicenseForm(props) {
    const { license, onSubmit } = props;
    const { ChangeValidity } = useLicenses();
    const [value, setValue] = useState(dayjs(license?.validity));

    const changeValidity = async (day) => {
        setValue(day);
        const body = {
            today: dayjs(license?.today).format('DD/MM/YYYY'),
            lastValidity: dayjs(license?.validity).format('DD/MM/YYYY'),
            validity: day.format('DD/MM/YYYY'),
        }

        await ChangeValidity(license?.id, body);
        onSubmit(true);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    label="Vigencia"
                    value={value}
                    onChange={(newDay) => changeValidity(newDay)}
                    format="DD/MM/YYYY"
                    sx={{ width: '100%' }}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}
