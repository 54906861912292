// @mui
import { Card, Stack, Grid, Container, Typography, Skeleton, } from '@mui/material';
// components
import TableCustomer from '../../components/table/TableCustomer';
// hooks
import { useLogs } from '../../hooks';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'user_id', label: 'Usuario', alignRight: false },
  { id: 'api', label: 'API', alignRight: false },
  { id: 'method', label: 'Método', alignRight: false },
  { id: 'code', label: 'Código', alignRight: false },
  { id: 'description', label: 'Descripción', alignRight: false },
  { id: 'created_at', label: 'Registro', alignRight: false },
  { id: '' },
];

export default function LogPage() {
  const { logs, loadingInfo } = useLogs();
  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Logs interno
        </Typography>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            {
              loadingInfo ? 
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={1200} height={350} />
              </Stack>
              : 
              <TableCustomer tableHead={TABLE_HEAD} typeTable="logs" data={logs} />
            }
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
