import { useState } from 'react';
import { LoginSchema } from '../utils';

export const useForm = ( initialForm = {} ) => {
  
    const [ formState, setFormState ] = useState( initialForm );
    const [errors, setErrors] = useState({});

    const inputChange = ({ target }) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: value,
        });
        LoginSchema.validateAt(name, { [name]: value })
        .then(() => {
            setErrors({ ...errors, [name]: null });
        })
        .catch((err) => {
            setErrors({ ...errors, [name]: err.errors[0] });
        });
    }

    const onResetForm = () => {
        setFormState( initialForm );
    }

    return {
        ...formState,
        formState,
        errors,
        setErrors,
        inputChange
    }
}