/* eslint-disable react/prop-types */
import React, { useState } from 'react'
// @mui
import { Stack, Popover, TableRow, Avatar, MenuItem, TableCell, Typography, IconButton, Backdrop, CircularProgress  } from '@mui/material';

import { LicenseForm } from '../../forms';
import Iconify from '../../iconify';
import Label from '../../label';
import Modal from '../../modal';
import { useLicenses } from '../../../hooks';

export default function BodyLicense(props){
    const { filtered, page, rowsPerPage, emptyRows, refresh, openBackdrop } = props;
    const { ChangeStatus } = useLicenses();

    const [data, setData] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const changeValidity = (row) => {
        setOpenModal(true);
        setData(row);
    }

    const changeStatus = async (row) => {
        await ChangeStatus(row.id);
        refresh();
    }

    const onSubmit = () => {
        setData(null);
        setOpenModal(false);
        refresh();
    }

  return (
    <>
      {
        filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const { id, addon, version, server, dbsap, status, today, validity } = row;
          return (
            <TableRow hover key={id} tabIndex={-1}>
                <TableCell component="th" scope="row">
                    {id}
                    {/* <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="subtitle2" noWrap>
                    </Typography>
                    </Stack> */}
                </TableCell>
                <TableCell component="th" scope="row">
                    {addon}
                    {/* <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="subtitle2" noWrap>
                    </Typography>
                    </Stack> */}
                </TableCell>
                <TableCell align="left">
                    {version}
                </TableCell>
                <TableCell align="left">
                    {/* <Label color={'info'}>{renderRole(role)}</Label> */}
                    {server}
                </TableCell>
                <TableCell align="left">
                    {/* <Label color={(isVerified === 1 ? 'success':'error')}>{(isVerified === 1 ? 'Sí':'No')}</Label> */}
                    {dbsap}
                </TableCell>
                <TableCell align="left">
                    <Label 
                        style={{cursor: 'pointer'}} 
                        onClick={() => changeStatus(row)} 
                        color={(status === 1 ? 'success':'error')}
                    >
                        {(status === 1 ? 'Activo':'Inactivo')}
                    </Label>
                </TableCell>
                <TableCell align="left">
                    {/* <Label color={'info'}>{renderRole(role)}</Label> */}
                    <Label 
                        style={{cursor: 'pointer'}} 
                        onClick={() => changeValidity(row)} 
                        color={'info'}
                    >
                        {validity}
                    </Label>
                </TableCell>
            </TableRow>
          );
        })
      }

      {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )
      }

      {
        <Modal title="Modificar vigencia" maxWidth={'xs'} open={openModal} onClose={() => setOpenModal(false)}>
            <LicenseForm license={data} onSubmit={onSubmit}/>
        </Modal>
      }
    </>
  )
}
