import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// sections
import { LoginForm } from '../../components/forms/auth';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage(){
  
  return (
    <>
      <Helmet>
        <title> Inicio Sesión | Licencias </title>
      </Helmet>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent sx={{ textAlign: "center" }}>
            {/* <img src="/assets/images/logos/oncompras.png" alt="login" /> */}
            <Typography variant="h4" gutterBottom sx={{ mb: 0}}>
              Inicio de Sesión
            </Typography>
            <Typography variant="body2" sx={{ mb: 3}}>
              Ingresa tus credenciales de acceso
            </Typography>
            <LoginForm />
            <Typography variant="body2" sx={{ mb: 0}}>
              Licencias 2023©
            </Typography>
            <Typography variant="body2" sx={{ mb: 3}}>
              Todos los derechos reservados.
              Inteligencia NEFINTEC, S.A. de C.V.
            </Typography>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
