/* eslint-disable dot-notation */
import axios from 'axios';

const {REACT_APP_API_URL, REACT_APP_API_KEY} = process.env;

const api = axios.create({
  baseURL: `${REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  },
});

api.interceptors.request.use( config => {
  config.headers = {
    ...config.headers,
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  }
  return config;
});

export default api;