import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux'; 
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GetUsers, GetUser, PostUser, PutUser, GetResetPass } from '../../service';
import { onUser } from '../../store/user';

export const useUsers = () =>{
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useSelector(state => state.user);
  const [ users, setUsers ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ loadingInfo, setLoadingInfo ] = useState(false);
  const [ sendResponse, setSendResponse ] = useState(false);

  async function usersData(){
    try {
      const { data } = await GetUsers();
      setLoading(false);
      setLoadingInfo(false);
      setUsers(data.result);
    } catch (error) {
      setLoading(true);
      toast.error(error.response.data.error);
    }
  }

  useEffect(() => {
    usersData();
  }, []);

  const FindUser = async (id) => {
    try {
      const { data } = await GetUser(id);
      setLoading(false);
      dispatch(onUser(data.result));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const createUser = async (body) => {
    setLoading(true);
    await PostUser(body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        toast.success(data.message);
        // enqueueSnackbar(`${data.message}`, {
        //   variant: 'success',
        //   autoHideDuration: 3000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   action: (key) => (
        //     <IconButton onClick={() => { closeSnackbar(key) }}>
        //       <CloseIcon />
        //     </IconButton>
        //   )
        // });
      }
    ).catch(
      (error) => {
        console.log(error);
        setLoading(false);
        setSendResponse(false);
        toast.error(error.response.data.error);
        // enqueueSnackbar(`${error.response.data.error}`, {
        //   variant: 'error',
        //   autoHideDuration: 3000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   action: (key) => (
        //     <IconButton onClick={() => { closeSnackbar(key) }}>
        //       <CloseIcon />
        //     </IconButton>
        //   )
        // });
      }
    );
  }

  const UpdateUser = async (id, body) => {
    setLoading(true);
    await PutUser(id, body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        toast.success(data.message);
        // enqueueSnackbar(`${data.message}`, {
        //   variant: 'success',
        //   autoHideDuration: 3000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   action: (key) => (
        //     <IconButton onClick={() => { closeSnackbar(key) }}>
        //       <CloseIcon />
        //     </IconButton>
        //   )
        // });
      }
    ).catch(
      (error) => {
        console.log(error);
        setLoading(false);
        setSendResponse(false);
        toast.error(error.response.data.error);
        // enqueueSnackbar(`${error.response.data.error}`, {
        //   variant: 'error',
        //   autoHideDuration: 3000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   action: (key) => (
        //     <IconButton onClick={() => { closeSnackbar(key) }}>
        //       <CloseIcon />
        //     </IconButton>
        //   )
        // });
      }
    );
  }

  const UpdateProfile = async (id, body) => {
    setLoading(true);
    await PutUser(id, body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        dispatch(onUser(data.result));
        toast.success(data.message);
        // enqueueSnackbar(`${data.message}`, {
        //   variant: 'success',
        //   autoHideDuration: 3000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   action: (key) => (
        //     <IconButton onClick={() => { closeSnackbar(key) }}>
        //       <CloseIcon />
        //     </IconButton>
        //   )
        // });
      }
    ).catch(
      (error) => {
        console.log(error);
        setLoading(false);
        setSendResponse(false);
        toast.error(error.response.data.error);
        // enqueueSnackbar(`${error.response.data.error}`, {
        //   variant: 'error',
        //   autoHideDuration: 3000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   action: (key) => (
        //     <IconButton onClick={() => { closeSnackbar(key) }}>
        //       <CloseIcon />
        //     </IconButton>
        //   )
        // });
      }
    );
  }

  const ResetPassword = async (id) => {
    await GetResetPass(id).then(
      (result) => {
        const { data } = result;
        console.log(data);
        setLoading(false);
        setSendResponse(true);
        toast.success(data.message);
        // enqueueSnackbar(`${data.message}`, {
        //   variant: 'success',
        //   autoHideDuration: 3000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   action: (key) => (
        //     <IconButton onClick={() => { closeSnackbar(key) }}>
        //       <CloseIcon />
        //     </IconButton>
        //   )
        // });
      }
    ).catch(
      (error) => {
        console.log(error);
        setLoading(false);
        setSendResponse(false);
        toast.error(error.response.data.error);
        // enqueueSnackbar(`${error.response.data.error}`, {
        //   variant: 'error',
        //   autoHideDuration: 3000,
        //   anchorOrigin: {
        //     vertical: 'top',
        //     horizontal: 'center',
        //   },
        //   action: (key) => (
        //     <IconButton onClick={() => { closeSnackbar(key) }}>
        //       <CloseIcon />
        //     </IconButton>
        //   )
        // });
      }
    );
  }

  return { user, users, loading, loadingInfo, sendResponse, usersData, FindUser, createUser, UpdateUser, UpdateProfile, ResetPassword }
}