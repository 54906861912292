import axios from '../config/config';

export const PostAuth = (body) => axios.post(`auth`, body);
export const GetAuth = () => axios.get(`auth`);

// Logs
export const GetLogs = () => axios.get(`log`);
export const GetLog = (id) => axios.get(`log/${id}`);

// Users
export const GetUsers = () => axios.get(`user`);
export const GetUser = (id) => axios.get(`user/${id}`);
export const PostUser = (body) => axios.post(`user`, body);
export const PutUser = (id, body) => axios.put(`user/${id}`, body);

export const GetResetPass = (id) => axios.get(`user/reset-password/${id}`);
// License
export const GetLicense = () => axios.get(`license`);
export const DeleteLicense = (id) => axios.delete(`license/${id}`);
export const UpdateLicense = (id, body) => axios.put(`license/${id}`, body);
// export const GetBusines = (id) => axios.get(`business/${id}`);
// export const PostBusiness = (body) => axios.post(`business`, body);
// export const PutBusiness = (id, body) => axios.put(`business/${id}`, body);
