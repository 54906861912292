import SvgColor from '../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Inicio',
    path: '/inicio',
    icon: icon('ic_apps'),
    role: ['develop','admin','consult','client','user'],
  },
  {
    title: 'Usuarios',
    path: '/usuarios',
    icon: icon('ic_users'),
    role: ['develop','admin','consult','client'],
  },
  {
    title: 'Licencias',
    path: '/licencias',
    icon: icon('ic_license-line'),
    role: ['develop','admin','consult'],
  },
  {
    title: 'Logs',
    path: '/logs',
    icon: icon('ic_log'),
    role: ['develop','admin'],
  },
];

export default navConfig;
