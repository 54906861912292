export const PublicRoute = {
    LOGIN   : 'login'
};

export const PrivateRoute = {
    HOME        : 'inicio',
    USERS       : 'usuarios',
    PROFILE     : 'perfil',
    LICENSE     : 'licencias',
    LOGGER      : 'logs',
    UNI         : '*',
}