import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage, HomePage, UserPage, Page404, ProfilePage, LogPage, LicensePage } from '../pages';
import { PrivateRoute, PublicRoute } from './config';
import { AuthGuard, AuthRole } from '../guards';

function Router(){
    return (
        <Routes>
            <Route path="/" element={<Navigate to={PrivateRoute.HOME} />}/>
            <Route path={PublicRoute.LOGIN} element={<LoginPage />}/>
            <Route element={<AuthGuard />}>
                <Route path={PrivateRoute.HOME} element={<HomePage />}/>
                <Route path={PrivateRoute.PROFILE} element={<ProfilePage />}/>

                <Route element={ <AuthRole roles={['develop', 'admin']} /> }>
                    <Route path={PrivateRoute.LOGGER} element={<LogPage />}/>
                </Route>

                <Route element={ <AuthRole roles={['develop', 'admin', 'consult']} /> }>
                    <Route path={PrivateRoute.USERS} element={<UserPage />}/>
                    <Route path={PrivateRoute.LICENSE} element={<LicensePage />}/>
                </Route>
                
                <Route path={PrivateRoute.UNI} element={<Page404 />}/>
            </Route>
        </Routes>
    );
}

export default Router;