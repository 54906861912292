/* eslint-disable consistent-return */
import { useDispatch, useSelector } from 'react-redux'; 
import { PostAuth, GetAuth } from '../service';
import { onLogin, onChecking, onLogout } from '../store/auth';
import { onUser } from '../store/user';

export const useAuthStore = () => {
    const dispatch = useDispatch();
    const { status, data } = useSelector(state => state.auth);

    const login = async (body) => {
        dispatch(onChecking());
        try {
            const { data } = await PostAuth(body);
            console.log(data);
            localStorage.setItem('email', body.email);
            localStorage.setItem('token', data.result.token);
            dispatch(onLogin(data.result));
            dispatch(onUser(data.result.user));
        } catch (error) {
            console.log(error);
            dispatch(onLogout());
        }
    }

    const logout = () => {
        localStorage.removeItem('token');
        dispatch(onLogout());
    }

    const checkAuthToken = async () => {
        dispatch(onChecking());
        try {
            const { data } = await GetAuth();
            localStorage.setItem('token', data.result.token);
            dispatch(onLogin(data.result));
            dispatch(onUser(data.result.user));
        } catch (error) {
            localStorage.clear();
            dispatch(onLogout());
        }
    }

    return {
        // Propiedades
        status,
        data,
        // Metodos
        login,
        logout,
        checkAuthToken,
    }
}