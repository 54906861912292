/* eslint-disable react-hooks/rules-of-hooks */
import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, 'Estructura del correo electrónico no es valido')
        .required('Correo electrónico es requerido'),
    password: yup
        .string()
        .min(7, 'Mínimo 7 carácteres')
        .required('Contraseña es requerida'),
});

export const RegisterSchema = yup.object().shape({
    name: yup
        .string()
        .min(2, 'Minimo, 2 carácteres')
        .required('Nombre(s) es requerido'),
    lastNameP: yup
        .string()
        .min(2, 'Minimo, 2 carácteres')
        .required('Apellido es requerido'),
    lastNameM: yup
        .string()
        .min(2, 'Minimo, 2 carácteres')
        .required('Apellido es requerido'),
    email: yup
        .string()
        .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, 'Estructura del correo electrónico no es valido')
        .required('Correo electrónico es requerido'),
});
