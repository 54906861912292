import React, { useState } from 'react';
import { Card, Stack, Grid, Container, Typography, Skeleton, } from '@mui/material';
import { useLicenses } from '../../hooks';
import TableCustomer from '../../components/table';

const TABLE_HEAD = [
    { id: 'id', label: '#', alignRight: false },
    { id: 'addon', label: 'Addon', alignRight: false },
    { id: 'version', label: 'Version', alignRight: false },
    { id: 'server', label: 'Servidor', alignRight: false },
    { id: 'dbsap', label: 'DB SAP', alignRight: false },
    { id: 'status', label: 'Estado', alignRight: false },
    // { id: 'today', label: 'Fecha de Hoy', alignRight: false },
    { id: 'validity', label: 'Vigencia', alignRight: false },
];

export default function LicensePage(){
    const { loading, licenses, AllLicenses } = useLicenses();
    const [loadingInfo, setLoadingInfo] = useState(true);

    const onSubmit = () => {
        AllLicenses();
    }

    return (
        <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Licencias
                </Typography>
            </Stack>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        {
                            loading ? 
                                <Stack spacing={1}>
                                    <Skeleton variant="rounded" width={1200} height={350} />
                                </Stack>
                            : 
                            <TableCustomer tableHead={TABLE_HEAD} typeTable="license" refresh={onSubmit} data={licenses} />
                        }
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}
