export const renderRole = (role) => {
  switch (role) { 
    case 'develop':
      return 'Desarrollador';
    case 'admin':
      return 'Administrador';
    case 'consult':
      return 'Consultor';
    case 'user':
        return 'Usuario';
    case 'client':
      return 'Cliente';
    default:
      return 'N/A';
  }
}
  
export const renderName = (name, lastNameP, lastNameM = '') => `${name} ${lastNameP} ${lastNameM}`;
