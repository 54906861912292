import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { GetLicense, UpdateLicense, DeleteLicense } from '../../service';

export const useLicenses = () =>{
  const [ licenses, setLicenses ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ loadingInfo, setLoadingInfo ] = useState(false);

  useEffect(() => {
    AllLicenses();
  }, []);

  async function AllLicenses(){
    try {
      const { data } = await GetLicense();
      console.log(data)
      setLoading(false);
      setLoadingInfo(false);
      setLicenses(data.result);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error);
    }
  }

  const ChangeStatus = async (id) => {
    try {
      const { data } = await DeleteLicense(id);
      toast.success(data.message);
      console.log(data);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }

  const ChangeValidity = async (id, body) => {
    try {
      const { data } = await UpdateLicense(id, body);
      toast.success(data.message);
      console.log(data);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  }

  return { licenses, loading, loadingInfo, AllLicenses, ChangeStatus, ChangeValidity }
}